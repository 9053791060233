import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, computed, inject, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonList, IonItem, IonLabel, IonIcon, IonToggle } from '@ionic/angular/standalone';
import { addIcons } from 'ionicons';
import { card, person, logOut, document, help, moon, informationCircle } from 'ionicons/icons';
import { AuthService } from 'src/app/services/auth.service';
import { SettingsService } from 'src/app/services/settings.service';
import { RestService } from 'src/app/services/rest.service';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [IonToggle, CommonModule, FormsModule, IonList, IonItem, IonLabel, IonIcon]
})
export class UserMenuComponent  implements OnInit {

  public settingsService = inject(SettingsService);
  public authService = inject(AuthService);
  public restService = inject(RestService);
  public infoBoxVisible = inject(SettingsService).infoBoxVisible;

  public darkMode = computed(() => this.settingsService.theme() === 'dark');
  public menuItems  = signal([
    { title: 'Account', path: '/account', icon: 'person', active: true, action: () => this.authService.openUserAccount() },
    { title: 'Billing', path: '/billing', icon: 'card', active: false, action: () => this.restService.openBillginPortal() },
    // { title: 'Documentation', path: '/account', icon: 'document', active: false },
    { title: 'Support', path: '/contact', icon: 'help', active: false, action: () => window.open('https://corsproxy.io/contact/') },
    // { title: 'Logout', path: '/logout', icon: 'log-out', active: false, action: () => this.authService.signOut() },
  ]);

  constructor() { }

  ngOnInit() {

    addIcons({
      person,
      card,
      logOut,
      document,
      help,
      moon,
      informationCircle
    });
  }

  toggleDarkMode(ev: any) {
    this.settingsService.theme.set(ev.detail.checked ? 'dark' : 'light');
  }

  toggleInfoBox(ev: any) {
    this.infoBoxVisible.set(ev.detail.checked);
  }

}
